import styled from 'styled-components';

import colors from '@/design/colors';
import { Text } from '@/design/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  align-items: center;
`;

export const HeaderSeparator = styled.div`
  width: 1px;
  height: 45px;

  background: #d9dbe3;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-left: auto;
`;

export const Info = styled.div`
  width: 24px;
  height: 24px;
  position: relative;

  > button {
    width: 24px;
    height: 24px;
    background-color: #f0f2f5;
    border-radius: 12px;
  }
`;

export const ExecucaoInfo = styled.div`
  color: ${colors.blackAlpha100};

  ${Text(12)}
  font-weight: 600;
`;
