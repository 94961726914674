import colors from '@/design/colors';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const SideBoxButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-height: 48px;

  padding: 10px;
  gap: 10px;

  color: ${colors.gray200};
  > svg > path,
  rect {
    stroke: ${colors.gray200};
  }

  :hover {
    color: ${colors.purple100};

    > svg > path,
    rect {
      stroke: ${colors.purple100};
    }
  }

  cursor: pointer;
`;

export const SideBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 16px;
  margin-top: 16px;

  .active > ${SideBoxButton} {
    color: ${colors.purple100};
    background: ${colors.purple50};
    border-right: 4px solid ${colors.purple100};

    > svg > path,
    rect {
      stroke: ${colors.purple100};
    }
  }
`;

export const SideBoxInfoPopup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  background: ${colors.white};
  border: 1px solid ${colors.gray100};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  width: 360px;

  position: fixed;
  top: 160px;
  left: 104px;

  z-index: 10000;
`;

export const SideBoxInfoPopupIndicator = styled.div`
  width: 37px;
  height: 37px;

  position: fixed;
  top: 172px;
  left: 86px;

  z-index: 1000;

  > svg {
    fill: ${colors.white};
    filter: drop-shadow(-12px 0px 12px rgba(0, 0, 0, 0.14));
  }
`;

export const SideBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
