import styled from 'styled-components';

import colors from '@/design/colors';
import shadows from '@/design/shadows';

import { Text } from '@/design/typography';

export const Side = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  background-color: ${colors.white};
  box-shadow: ${shadows.shadowSideBox};

  overflow-x: hidden;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Header = styled.div`
  height: 60px;
  padding: 0 0 24px;
  margin: 0 12px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;

  border-bottom: 1px solid ${colors.gray100};
`;

export const Separator = styled.div`
  width: 46px;
  height: 1px;

  margin: 0 20px 0 20px;

  background-color: ${colors.gray100};
  border-radius: 10%;
`;

export const MedflowBanner = styled.div`
  position: absolute;
  left: 0px;
  bottom: 50px;

  display: flex;
  padding: 16px 8px;
  align-items: center;
  gap: 4px;

  height: 36px;
  width: 170px;

  border-radius: 0px 0px 4px 4px;
  background: var(--Gray-50, #f0f2f5);
  box-shadow: 1px 1px 1.5px 0px rgba(0, 0, 0, 0.16);

  transform: rotate(-90deg);
  transform-origin: top left;
`;

export const MedflowBannerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;

  gap: 4px;

  > img {
    height: 16px !important;
  }
`;

export const MedflowBannerText = styled.span`
  ${Text(12)};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
`;
