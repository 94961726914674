import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Logout from '@/features/auth/pages/Logout';
import NewPassword from '@/features/auth/pages/NewPassword';
import PageAuthorization from '@/features/auth/pages/PageAuthorization';
import Profile from '@/features/auth/pages/Profile';
import SignIn from '@/features/auth/pages/SignIn';
import Unauthorized from '@/features/auth/pages/Unauthorized';

import Calculadora from '@/features/calculadoras/pages/PageCalculadora';
import ListaCalculadoras from '@/features/calculadoras/pages/PageCalculadoraList';

import VisualizacaoProtocoloEnterprise from '@/features/enterprise/pages/VisualizacaoProtocolo';

import EspecialidadeList from '@/features/dashboard/pages/PageEspecialidadeList';
import EspecialidadeProtocoloList from '@/features/dashboard/pages/PageEspecialidadeProtocoloList';
import ListaProtocolo from '@/features/dashboard/pages/PageHome';
import ProtocoloExecutadoList from '@/features/dashboard/pages/PageProtocoloExecutadoList';

import AbrirExecucao from '@/features/execucao/pages/AbrirExecucao';
import ExecucaoProtocolo from '@/features/execucao/pages/ExecucaoProtocolo';

import ExecucaoProtocoloEnterprise from '@/features/enterprise/pages/ExecucaoProtocoloEnterprise';
import PageIntegracaoProtocolo from '@/features/enterprise/pages/PageIntegracaoProtocolo';

import PageHistoryDetail from '@/features/history/pages/PageHistoryDetail';
import PageHistoryList from '@/features/history/pages/PageHistoryList';

import withTracker from '@/utils/hooks/withTracker';

import InfoAtendimentoEnterprise from '@/features/enterprise/pages/InfoAtendimentoEnterprise';
import PrivateRoute from './PrivateRoute';
import PrivateRouteEnterprise from './PrivateRouteEnterprise';
import PublicRoute from './PublicRoute';

const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={withTracker(ListaProtocolo)} />
      <PrivateRouteEnterprise
        path="/protocolos-execucao"
        exact
        component={withTracker(ProtocoloExecutadoList)}
      />
      <PrivateRoute
        path="/calculadoras"
        exact
        component={withTracker(ListaCalculadoras)}
      />
      <PrivateRoute
        path="/calculadoras/:codigo"
        exact
        component={withTracker(Calculadora)}
      />
      <PrivateRoute
        path="/historicos"
        exact
        component={withTracker(PageHistoryList)}
      />
      <PrivateRoute
        path="/historicos/:id"
        exact
        component={withTracker(PageHistoryDetail)}
      />
      <PrivateRoute
        path="/especialidades"
        exact
        component={withTracker(EspecialidadeList)}
      />
      <PrivateRoute
        path="/especialidades/:id"
        exact
        component={withTracker(EspecialidadeProtocoloList)}
      />
      <PrivateRoute path="/perfil" exact component={withTracker(Profile)} />
      <PublicRoute path="/entrar" component={withTracker(SignIn)} restricted />
      <PrivateRoute path="/sair" component={withTracker(Logout)} />
      <PrivateRoute
        path="/enterprise/protocolos/:id/resumo"
        exact
        component={withTracker(VisualizacaoProtocoloEnterprise)}
      />
      <PrivateRouteEnterprise
        path="/enterprise/protocolos/:id"
        exact
        component={withTracker(ExecucaoProtocoloEnterprise)}
      />
      <PrivateRouteEnterprise
        path="/enterprise/protocolos/:id/info"
        exact
        component={withTracker(InfoAtendimentoEnterprise)}
      />
      <PrivateRouteEnterprise
        path="/enterprise/integracoes/:id"
        exact
        component={withTracker(PageIntegracaoProtocolo)}
      />
      <PrivateRoute
        path="/abrir-execucao"
        component={withTracker(AbrirExecucao)}
      />
      <PublicRoute
        path="/execucao"
        component={withTracker(ExecucaoProtocolo)}
      />
      <PublicRoute
        path="/auth/password/reset/confirm/:uid/:token"
        component={withTracker(NewPassword)}
      />
      <PublicRoute
        path="/enterprise/authorize/"
        component={withTracker(PageAuthorization)}
      />
      <PublicRoute path="/unauthorized" component={withTracker(Unauthorized)} />

      {/* NotFound routes */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
