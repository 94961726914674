import React from 'react';

import SideBox from '@/components/SideBox';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import { ReactComponent as MfIndicator } from '@/assets/icons/indicator.svg';
import { ReactComponent as MfUser } from '@/assets/icons/user-2.svg';
import { FiServer } from 'react-icons/fi';

import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { NavLink } from 'react-router-dom';
import SideBoxHistoricoInteracoes from '../SideBoxHistoricoInteracoes';

import DadosAtendimento from '../DadosAtendimento';
import {
  Container,
  SideBoxButton,
  SideBoxInfoPopup,
  SideBoxInfoPopupIndicator,
  SideBoxWrapper,
} from './styles';

const EnterpriseSidebar: React.FC = () => {
  const { user } = useAuth();
  const [{ protocoloExecutado }] = useExecucaoContext();

  const [isHistorySideOpen, setHistorySideOpen] = React.useState(false);
  const [showAtendimentoInfo, setShowAtendimentoInfo] = React.useState(false);

  const Logo = React.useMemo(() => {
    if (user && user.empresa && user.empresa.logo) {
      return (
        <img
          width={32}
          height={32}
          src={user.empresa.logo}
          alt={user.empresa.nome}
        />
      );
    }

    return undefined;
  }, [user]);

  return (
    <Container>
      <SideBoxHistoricoInteracoes
        isOpen={isHistorySideOpen}
        onCloseSideBox={() => setHistorySideOpen(false)}
      />
      <SideBox style={{ width: 80 }} LogoComponent={Logo}>
        <SideBoxWrapper>
          <NavLink
            exact
            to={`/enterprise/protocolos/${protocoloExecutado?.id}/`}
          >
            <SideBoxButton>
              <FiServer />
            </SideBoxButton>
          </NavLink>
          <SideBoxButton
            onMouseEnter={() => setShowAtendimentoInfo(true)}
            onMouseLeave={() => setShowAtendimentoInfo(false)}
          >
            <MfUser />
          </SideBoxButton>
          {showAtendimentoInfo && (
            <SideBoxInfoPopup>
              <SideBoxInfoPopupIndicator>
                <MfIndicator />
              </SideBoxInfoPopupIndicator>
              <DadosAtendimento
                configs={['patient', 'encounter', 'organization']}
              />
            </SideBoxInfoPopup>
          )}

          {/* <UltimaInteracao onExpandClick={() => setHistorySideOpen(true)} /> */}
        </SideBoxWrapper>
      </SideBox>
    </Container>
  );
};

export default EnterpriseSidebar;
