import styled, { css } from 'styled-components';

/**
 * Placeholder p/ aplicar em conteúdos de tela
 * Todo conteúdo só pode assumir no máximo 1284px de largura
 */
export const PageContentWrapper = css`
  margin: 0 auto;
  width: min(100%, 1284px);
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  padding: 24px;
  display: flex;

  ${PageContentWrapper};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
