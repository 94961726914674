import { enforceElement, RenderableElement } from '@/utils/elements';
import React from 'react';

import Feature from '@/features/features/components/Feature';
import MedflowIso from '../Logo/MedflowIso';
import MedflowLogo from '../Logo/MedflowLogo';
import {
  Content,
  Footer as FooterContainer,
  Header,
  MedflowBanner,
  MedflowBannerContent,
  MedflowBannerText,
  Side,
} from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
  className?: string;
  LogoComponent?: RenderableElement;
  FooterComponent?: RenderableElement;
}

const SideBox: React.FC<Props> = ({
  children,
  className,
  style,
  LogoComponent,
  FooterComponent,
}) => {
  const Footer = React.useMemo(
    () =>
      FooterComponent && (
        <FooterContainer>{enforceElement(FooterComponent)}</FooterContainer>
      ),
    [FooterComponent],
  );

  return (
    <Side className={className} style={style}>
      <Header>
        {LogoComponent ? (
          enforceElement(LogoComponent)
        ) : (
          <MedflowIso style={{ height: 32 }} />
        )}
      </Header>
      <Content>{children}</Content>
      <Feature name="EXIBE_BANNER">
        <MedflowBanner>
          <MedflowBannerContent>
            <MedflowBannerText>Criado com</MedflowBannerText>
            <MedflowLogo />
          </MedflowBannerContent>
        </MedflowBanner>
      </Feature>
      {Footer}
    </Side>
  );
};

export default SideBox;
