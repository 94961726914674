import React from 'react';

import Button from '@/components/Button';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import ExecucaoService from '@/features/execucao/services/ExecucaoAPI';
import { useHistory, useParams } from 'react-router-dom';
import DadosAtendimento from '../../components/DadosAtendimento';
import EnterpriseHeaderProtocoloExecutado from '../../components/EnterpriseHeaderProtocoloExecutado';
import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import EnterprisePageLayout from '../../layouts/EnterprisePageLayout';
import { Actions, Container } from './styles';

const InfoAtendimentoEnterprise: React.FC = () => {
  const history = useHistory();
  const protocoloId = useParams<{ id: string }>().id;

  const [
    { protocoloExecutado },
    { dispatchSetExecucao },
  ] = useExecucaoContext();
  const [, { dispatchStartFromExecution }] = useEnterpriseContext();

  const redirectToInstance = React.useCallback(() => {
    if (protocoloId) {
      history.replace(`/enterprise/protocolos/${protocoloId}/`);
    }
  }, [history, protocoloId]);

  React.useEffect(() => {
    async function loadExecucao() {
      try {
        const execucao = await ExecucaoService.getExecucao(protocoloId, {
          omit: ['passo_corrente'],
          expand: ['passos'],
        });
        dispatchSetExecucao(execucao);
        dispatchStartFromExecution(execucao);
      } catch {
        history.replace('/unauthorized');
      }
    }

    if (protocoloExecutado) {
      dispatchStartFromExecution(protocoloExecutado);
    } else {
      loadExecucao();
    }
  }, [
    dispatchSetExecucao,
    dispatchStartFromExecution,
    history,
    protocoloExecutado,
    protocoloId,
  ]);

  return (
    <EnterprisePageLayout Nav={<EnterpriseHeaderProtocoloExecutado />}>
      <Container>
        <DadosAtendimento />
        <Actions>
          <Button
            theme="secondary"
            onClick={() => {
              redirectToInstance();
            }}
          >
            Voltar para o protocolo
          </Button>
        </Actions>
      </Container>
    </EnterprisePageLayout>
  );
};

export default InfoAtendimentoEnterprise;
