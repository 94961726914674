import React from 'react';

import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import PlaceholderDadosAtendimento from '../PlaceholderDadosAtendimento';
import DoctorSection from './DoctorSection';
import EncounterSection from './EncounterSection';
import OrganizationSection from './OrganizationSection';
import PatientSection from './PatientSection';
import { Container } from './styles';

type configType = 'doctor' | 'encounter' | 'patient' | 'organization';
interface DadosAtendimentoProps {
  configs?: Array<configType>;
}

const DadosAtendimento: React.FC<DadosAtendimentoProps> = ({ configs }) => {
  const [{ history, encounter }] = useEnterpriseContext();
  const loading = React.useMemo(
    // Apesar de loadings diferentes é visualmente melhor exibir a sidebar
    // apenas quando ambos os dados (atendimento e histórico) estejam carregados
    () => [history.status, encounter.status].includes('on-hold'),
    [encounter.status, history.status],
  );

  const contentMap = {
    doctor: <DoctorSection key="doctor" />,
    patient: <PatientSection key="patient" />,
    encounter: <EncounterSection key="encounter" />,
    organization: <OrganizationSection key="organization" />,
  };

  return (
    <LoadingSwitchTransition
      loading={loading}
      LoadingComponent={<PlaceholderDadosAtendimento />}
      style={{ width: '100%' }}
    >
      <Container>
        {configs?.map(config => {
          return contentMap[config];
        })}
      </Container>
    </LoadingSwitchTransition>
  );
};

DadosAtendimento.defaultProps = {
  configs: ['doctor', 'encounter', 'patient', 'organization'],
};

export default DadosAtendimento;
