import React from 'react';

import primary from '@/assets/logos/medflow-iso.svg';
import alternative from '@/assets/logos/medflow-iso-alternative.svg';
import positive from '@/assets/logos/medflow-iso-positive.svg';
import negative from '@/assets/logos/medflow-iso-negative.svg';

import { MedflowLogoVersion, MedflowVersionMapping } from '../types';

const LogoSourceMapping: MedflowVersionMapping = {
  primary,
  alternative,
  positive,
  negative,
};

interface Props {
  version?: MedflowLogoVersion;
  className?: string;
  style?: React.CSSProperties;
}

const MedflowIso: React.FC<Props> = ({
  version = 'primary',
  className,
  style = {
    width: 32,
    height: 32,
  },
}) => {
  const src = React.useMemo(() => LogoSourceMapping[version], [version]);

  return <img src={src} alt="Medflow" style={style} className={className} />;
};

export default MedflowIso;
