import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Alert } from '@/assets/illustrations/feedback-graphics/alert.svg';
import ProtocoloExecutadoDetail from '@/features/execucao/components/ProtocoloExecutadoDetail';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import ExecucaoService from '@/features/execucao/services/ExecucaoAPI';

import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import PlaceholderLoading from '@/components/PlaceholderLoading';

import EnterpriseHeaderProtocoloExecutado from '../../components/EnterpriseHeaderProtocoloExecutado';
import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import EnterprisePageLayout from '../../layouts/EnterprisePageLayout';
import { ErrorContainer } from './styles';

const PlaceholderContent = () => (
  <PlaceholderLoading.Rect
    height={513}
    width="100%"
    style={{
      display: 'flex',
      borderRadius: 16,
    }}
  />
);

const ExecucaoProtocoloEnterprise: React.FC = () => {
  const history = useHistory();
  const protocoloId = useParams<{ id: string }>().id;

  const [, { dispatchStartFromExecution }] = useEnterpriseContext();

  const [isLoadingProtocolo, setIsLoadingProtocolo] = useState(true);
  const [isError, setIsError] = useState(false);
  const [
    { protocoloExecutado },
    { dispatchSetExecucao },
  ] = useExecucaoContext();

  React.useEffect(() => {
    if (protocoloExecutado) {
      setIsLoadingProtocolo(false);
    }
  }, [protocoloExecutado]);

  React.useEffect(() => {
    if (!isLoadingProtocolo && !protocoloExecutado) {
      setIsError(true);
    }
  }, [history, isLoadingProtocolo, protocoloExecutado]);

  React.useEffect(() => {
    async function loadProtocolo() {
      try {
        const execucao = await ExecucaoService.getExecucao(protocoloId, {
          omit: ['passo_corrente'],
          expand: ['passos'],
        });
        dispatchSetExecucao(execucao);
        dispatchStartFromExecution(execucao);
      } catch {
        setIsError(true);
      }
    }

    loadProtocolo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isError ? (
        <ErrorContainer>
          <Alert />

          <h3>Ops, algo deu errado.</h3>

          <p>Por favor, tente novamente em alguns instantes.</p>
        </ErrorContainer>
      ) : (
        <>
          <EnterprisePageLayout Nav={EnterpriseHeaderProtocoloExecutado}>
            <LoadingSwitchTransition
              loading={!protocoloExecutado}
              LoadingComponent={<PlaceholderContent />}
              style={{
                flex: 1,
              }}
            >
              <ProtocoloExecutadoDetail />
            </LoadingSwitchTransition>
          </EnterprisePageLayout>
        </>
      )}
    </>
  );
};

export default ExecucaoProtocoloEnterprise;
