import colors from '@/design/colors';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 0%;
  display: flex;
  max-width: 400px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: ${colors.white};

  > div > div {
    padding: 0px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-top: 1px solid ${colors.gray100};
  padding-top: 16px;

  a,
  button {
    flex: 1;
    width: 100%;
  }
`;
