import React, { useState } from 'react';

import { chunks } from '@/utils/collections';
import { Pergunta } from '@/models/Pergunta';
import { TipoVariavel } from '@/models/Expressao';

import { RespostaPayload } from '@/features/execucao/types';

import PerguntaDetail, { PerguntaDetailRef } from '../../../PerguntaDetail';

import { Content, List, Row } from './styles';

interface PerguntasContentProps {
  perguntas: Pergunta[];
  disabled: boolean;
  handleResposta: (resposta: RespostaPayload) => void;
  handleSetQuestionnaireRef: (ref: PerguntaDetailRef, nome: string) => void;
}

type TipoGrupoPergunta = 'number' | 'string' | 'others';

const getTipoGrupo = (pergunta: Pergunta): TipoGrupoPergunta => {
  if (!pergunta.variavel.opcoes.length) {
    const {
      variavel: { tipo },
    } = pergunta;
    if (
      [
        TipoVariavel.DECIMAL,
        TipoVariavel.INTEIRO,
        TipoVariavel.NUMERICA,
      ].includes(tipo)
    ) {
      return 'number';
    }

    if (TipoVariavel.STRING === tipo) {
      return 'string';
    }
  }

  return 'others';
};

const PerguntasContent: React.FC<PerguntasContentProps> = ({
  perguntas,
  disabled,
  handleResposta,
  handleSetQuestionnaireRef,
}: PerguntasContentProps) => {
  const [descriptionSizes, setDescriptionSizes] = useState({
    numericas: 18,
    strings: 18,
    outras: 18,
  });

  const sortedGroups = React.useMemo(() => {
    return perguntas.reduce<[TipoGrupoPergunta, Pergunta[]][]>(
      (result, current) => {
        const [groupType, items] = result[result.length - 1] || [];
        if (groupType && groupType === getTipoGrupo(current)) {
          items.push(current);
        } else {
          result.push([getTipoGrupo(current), [current]]);
        }

        return result;
      },
      [],
    );
  }, [perguntas]);

  const addDescriptionSize = React.useCallback(
    (rectHeight: number, perguntaType: TipoVariavel) => {
      if (
        [
          TipoVariavel.NUMERICA,
          TipoVariavel.INTEIRO,
          TipoVariavel.DECIMAL,
        ].includes(perguntaType)
      ) {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            numericas:
              rectHeight > prev.numericas ? rectHeight : prev.numericas,
          },
        }));
      } else if (perguntaType === TipoVariavel.STRING) {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            strings: rectHeight > prev.strings ? rectHeight : prev.strings,
          },
        }));
      } else {
        setDescriptionSizes(prev => ({
          ...prev,
          ...{
            outras: rectHeight > prev.outras ? rectHeight : prev.outras,
          },
        }));
      }
    },
    [],
  );

  const getGroupMaxHeight = React.useCallback(
    (group: TipoGrupoPergunta) => {
      if (group === 'number') {
        return descriptionSizes.numericas;
      }

      if (group === 'string') {
        return descriptionSizes.strings;
      }

      return 0;
    },
    [descriptionSizes.numericas, descriptionSizes.strings],
  );

  const groupItemsPerRow = React.useCallback((group: TipoGrupoPergunta) => {
    switch (group) {
      case 'string':
        return 2;
      case 'number':
        return 3;
      default:
        return 1;
    }
  }, []);

  const getGroupDescriptionSizeHandler = React.useCallback(
    (group: TipoGrupoPergunta) => {
      if (group === 'string') {
        return addDescriptionSize;
      }

      return undefined;
    },
    [addDescriptionSize],
  );

  const renderPergunta = React.useCallback(
    (group: TipoGrupoPergunta, pergunta: Pergunta) => {
      return (
        <PerguntaDetail
          key={pergunta.id}
          ref={perguntaRef => {
            if (perguntaRef) {
              handleSetQuestionnaireRef(perguntaRef, pergunta.variavel.nome);
            }
          }}
          pergunta={pergunta}
          disabled={disabled}
          onChange={handleResposta}
          addDescriptionSize={getGroupDescriptionSizeHandler(group)}
        />
      );
    },
    [
      disabled,
      getGroupDescriptionSizeHandler,
      handleResposta,
      handleSetQuestionnaireRef,
    ],
  );

  return (
    <Content>
      {sortedGroups.map(([group, items], groupIndex) => {
        const itemsPerRow = groupItemsPerRow(group);

        if (itemsPerRow > 1) {
          const chunked = chunks(items, itemsPerRow);
          return chunked.map((row, rowIndex) => (
            <Row
              // eslint-disable-next-line react/no-array-index-key
              key={`${group}-${groupIndex}-${rowIndex}`}
              maxHeight={getGroupMaxHeight(group)}
            >
              {row.map(pergunta => renderPergunta(group, pergunta))}
            </Row>
          ));
        }

        return (
          <List>{items.map(pergunta => renderPergunta(group, pergunta))}</List>
        );
      })}
    </Content>
  );
};

export default PerguntasContent;
