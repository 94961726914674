import styled from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

export const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PatientRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PatientBoxContent = styled.div`
  background: ${colors.gray50};
  display: flex;
`;

export const PatientContainerTitle = styled.span`
  ${HeadingSmall(16)};
  margin-bottom: 8px;
`;

export const PatientBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: ${colors.gray50};
  padding: 16px;
  border-radius: 10px;
  min-height: 156px;
`;

export const PatientImage = styled.img`
  height: 46px;
  width: 46px;
  border-radius: 50%;
  object-fit: cover;

  margin-right: 16px;
`;

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PatientInfoItem = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const PatientInfoItemTitle = styled.span`
  ${HeadingSmall(14)};
`;

export const PatientInfoItemValue = styled.span`
  color: ${colors.gray300};
  ${Text(14)};
`;
